import React, { forwardRef } from 'react';

const WalletButton = forwardRef(({ dataAos, dataAosDelay, ...props }, ref) => {
    return (
      <button
      className="border border-primary text-primary px-5 md:px-10 py-1.5 text-[13px] md:text-button font-normal rounded-button overflow-hidden transition duration-1000 ease-in-out transition-property: background-color, color hover:bg-primary hover:text-background"


        data-aos={dataAos}
        data-aos-delay={dataAosDelay}
        ref={ref}
        {...props}
      >
        Connect Wallet
      </button>
    );
  });
  

export default WalletButton;