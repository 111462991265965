import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = ({ id, name, logo, aosDelay }) => {
    return (
        <Link
            to={`/project/${id}`}
            className='w-full md:w-[200px] aspect-square border-2 border-white flex flex-col items-center justify-between p-4 relative transition-colors duration-500 ease-in-out group hover:border-primary overflow-hidden'
            data-aos="fade-up"
            data-aos-delay={aosDelay}
        >
            <h3 className='text-center text-white text-[20px] md:text-default font-bold whitespace-nowrap h-[40px]'>
                {name}
            </h3>
            <div className='flex justify-center items-center h-[100px] w-full'>
                <img src={`${logo}`} alt={name} className='max-h-full max-w-full object-contain h-[50px] w-auto md:w-full md:h-full' />
            </div>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute right-[10px] top-[10px] opacity-0 transition-opacity duration-700 ease-in-out group-hover:opacity-100'>
                <path d="M21.5 2C21.5 1.17157 20.8284 0.5 20 0.5L6.5 0.5C5.67157 0.5 5 1.17157 5 2C5 2.82843 5.67157 3.5 6.5 3.5H18.5V15.5C18.5 16.3284 19.1716 17 20 17C20.8284 17 21.5 16.3284 21.5 15.5L21.5 2ZM3.06066 21.0607L21.0607 3.06066L18.9393 0.93934L0.93934 18.9393L3.06066 21.0607Z" fill='#33E4F1' />
            </svg>
        </Link>

    );
}

export default ProjectCard;
