import React, { useState, useMemo, useEffect  } from 'react';

const ProjectTable = ({ projects }) => {

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const sortedProjects = useMemo(() => {
        let sortableItems = [...projects];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [projects, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const renderChange = (priceHistory) => {
        try {
            const data = JSON.parse(priceHistory);
            return data['24h'] || 0; // for example, gets the 24 hour price
        } catch {
            return 0;
        }
    };

    return (
        <table className='w-full table-auto'>
            <thead className='w-full after:content-[""] after:block after:w-100 after:bg-transparent md:after:h-[50px] after:h-[20px]'>
                <tr className='w-full md:text-table text-[9px]' data-aos="fade-right">
                    <th
                        onClick={() => requestSort('name')}
                        className="text-start relative cursor-pointer group w-auto">
                        Name
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-1/2 left-16 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                            <path d="M5 8L0.669873 0.5L9.33013 0.500001L5 8Z" fill="white" />
                        </svg>
                    </th>
                    <th
                        onClick={() => requestSort('price')}
                        className="text-start relative cursor-pointer group w-auto">
                        Price
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-1/2 left-14 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                            <path d="M5 8L0.669873 0.5L9.33013 0.500001L5 8Z" fill="white" />
                        </svg>
                    </th>
                    <th
                        className="text-start relative cursor-pointer group w-auto">
                        Change
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-1/2 left-24 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                            <path d="M5 8L0.669873 0.5L9.33013 0.500001L5 8Z" fill="white" />
                        </svg>
                    </th>
                    <th
                        className="text-start relative cursor-pointer group w-auto">
                        Volume
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-1/2 left-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                            <path d="M5 8L0.669873 0.5L9.33013 0.500001L5 8Z" fill="white" />
                        </svg>
                    </th>
                    <th
                        onClick={() => requestSort('market_cap')}
                        className="text-start relative cursor-pointer group w-auto">
                        Market Cap
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-1/2 left-32 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                            <path d="M5 8L0.669873 0.5L9.33013 0.500001L5 8Z" fill="white" />
                        </svg>
                    </th>

                </tr>
            </thead>
            <tbody className='w-full'>
                {sortedProjects.map((project, index) => (
                    <tr key={project.id} 
                        className='w-full md:text-[18px] text-[9px] font-bold'
                        data-aos="fade-right"
                        data-aos-delay={`${index * 100}`}
                    >
                        <td className='pb-[30px]'>
                            <div className="flex items-center justify-start space-x-8">
                                <div className='md:w-[100px] md:h-[40px] w-[40px] h-auto flex items-center justify-start overflow-hidden'>
                                    <img src={`${project.logo}`} alt={project.name} className='max-w-full max-h-full object-scale-down' />
                                </div>
                                <span className='flex items-center'>{project.name}</span>
                            </div>
                        </td>
                        <td>${project.price}</td>
                        <td>{renderChange(project.price_history)}</td>
                        <td>${project.volume_history ? JSON.parse(project.volume_history)['24h'] || 0 : 0}B</td>
                        <td>${project.market_cap}B</td>
                    </tr>
                ))}
            </tbody>

        </table>
    );
}

export default ProjectTable;
