// resources/js/pages/main.jsx

import React, { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import WalletButton from './../components/wallet-bnt';
import ProjectCard from './../components/project-card';
import ProjectTable from './../components/project-table';
import LinksSection from './../components/link-section';
import Divider from './../components/decoration-line';

const footerLinks = [
    {
        title: "SOCIAL MEDIA",
        titleHref: "/social-media",
        links: [
            { name: "TELEGRAM", href: "/telegram" },
            { name: "DISCORD", href: "/discord" },
            { name: "TWITTER", href: "/twitter" }
        ]
    },
    {
        title: "INTRODUCTION",
        titleHref: "/introduction",
        links: [
            { name: "TORUS SMART CONTRACT", href: "/torus-contract" },
            { name: "INVESTOR", href: "/investor" },
            { name: "STARTUP", href: "/startup" }
        ]
    },
    {
        title: "INFO",
        titleHref: "/info",
        links: [
            { name: "GETTING STARTED", href: "/getting-started" },
            { name: "WHO WE ARE", href: "/who-we-are" },
            { name: "WHY WE HERE", href: "/why-we-here" }
        ]
    },
    {
        title: "HAVE PROBLEMS?",
        titleHref: "/problems",
        links: [
            { name: "FAQ", href: "/faq" },
            { name: "SUPPORT", href: "/support" }
        ]
    }
];

const Main = () => {
    const [projects, setProjects] = useState([]);
    const elements = useRef([]);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });

        elements.current.forEach((element) => {
            if (element) {
                element.classList.add('aos-animate');
            }
        });

        axios.get('api/projects.json')
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the projects!', error);
            });
    }, []);

    return (
        <main className="bg-main_background text-text w-full overflow-x-clip min-h-screen font-jura">
            <div className='mx-auto max-w-[1440px]'>
                <section className='py-[25px] md:py-[100px] overflow-x-hidden'>
                    <div className='flex justify-center'>
                        <img src="images/logo.svg" alt="#logo"
                            className="animate-spin delay-1000 w-[100px] h-[100px] md:w-[200px] md:h-[200px]"
                            data-aos="zoom-in"
                            data-aos-delay="500"
                        />
                    </div>
                    <h1 className='text-center text-[29px] md:text-title font-bold md:pt-6'
                        data-aos="fade-up"
                        data-aos-delay="1000"
                    >
                        Torus
                    </h1>
                    <p className='text-center text-[11px] md:text-default font-normal pt-6 leading-[13px] md:leading-[28.39px]'
                        data-aos="fade-up"
                        data-aos-delay="2000"
                    >
                        We give people the opportunity to anonymously invest <br />
                        in projects in the form of NFT-STOCKS through <br />
                        smartcontracts without intermediaries, without being <br />
                        tied to countries, sanctions, budget, and other factors
                    </p>

                    <div className='flex justify-center py-8 md:py-16'>
                        <WalletButton
                            data-aos="zoom-in"
                            data-aos-delay="2500"
                            ref={(el) => (elements.current[3] = el)}
                        />
                    </div>
                    <Divider mt={10} aos="zoom-in" aosDelay="3000" />
                </section>
                <section>
                    <h2 className='pb-[40px] md:pb-0 text-center text-[20px] md:text-subtitle font-bold'
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-center"
                    >
                        Torus Ecosystem
                    </h2>

                    <div className='py-[30px] md:py-[80px] px-[35px] md:px-0'>
                        <div className='grid grid-cols-2 md:grid-cols-5 gap-x-10 gap-y-10 md:gap-y-20 justify-center'>
                            {projects.map((project, index) => (
                                <ProjectCard
                                    key={project.id}
                                    id={project.id}
                                    name={project.name}
                                    logo={project.logo}
                                    aosDelay={index * 100}
                                />
                            ))}
                        </div>
                    </div>



                    <Divider aos="zoom-in" />

                    <div className='py-[30px] md:py-[80px] md:px-0 px-[10px]'>
                        <ProjectTable projects={projects} />
                    </div>

                    <Divider aos="zoom-in" />

                    <div className='py-[30px] md:py-[80px] px-[20px] md:px-0'>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            {footerLinks.map((section, index) => (
                                <LinksSection key={index} title={section.title} titleHref={section.titleHref} links={section.links} />
                            ))}
                        </div>
                    </div>

                    <Divider aos="zoom-in" />
                </section>

                <footer className='py-[40px]'>
                    <h2 className='text-center font-bold text-[24px]'
                        data-aos="fade-up"
                    >
                        CAREERS
                    </h2>
                    <p className='text-[15px] text-center'
                        data-aos="fade-up"
                        data-aos-delay="100"
                    >
                        <span className='text-primary'>(13)</span> WE`RE HIRING
                    </p>
                </footer>
            </div>
        </main>
    );
};

export default Main;