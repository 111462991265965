import React from 'react';

const LinksSection = ({ title, titleHref, links }) => {
    return (
        <div>
            <h4 className='text-[12px] md:text-[24px] font-bold' data-aos="fade-up">
                <a href={titleHref} className='hover:text-primary hover:underline hover:underline-offset-8 duration-300'>
                    {title}
                </a>
            </h4>
            <ul className="pt-6 flex flex-col gap-4" data-aos-delay="200">
                {links.map((link, index) => (
                    <li key={index} className='text-[10px] md:text-[20px]' data-aos="fade-right" data-aos-delay={100 * index}>
                        <a href={link.href} className='hover:text-primary duration-300'>
                            <span>·</span><span className="ml-4">{link.name}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LinksSection;
