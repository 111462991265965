// resources/js/app.jsx

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './pages/main';
import './css/app.css';

const App = () => {
    return (
        <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <Routes>
            <Route path="/" element={<Main />} />
        </Routes>
    </Router>
    );
};

const root = createRoot(document.getElementById('root'));
root.render(<App />);
