import React from 'react';

const Divider = ({ mt = 0, aos = null, aosDelay = null }) => {
    return (
        <div
            className={`h-[1px] bg-text w-5/6 mx-auto ${mt > 0 ? `mt-${mt}` : ''}`}
            data-aos={aos ? aos : ''}
            data-aos-delay={aosDelay ? aosDelay : ''}
        ></div>
    );
};

export default Divider;
